import React from "react"
import Section from "../section"
import styled from "@emotion/styled"
import { css } from "@emotion/core"

const RedHeader = styled.h5`
  color: #fa4d60;
`

const SolutionsGrid = ({ children, title }) => (
  <Section
    css={css`
      padding-right: 0 !important;

      @media (max-width: 575px) {
        padding-left: 0 !important;
      }
    `}
  >
    <RedHeader>{title}</RedHeader>
    <div className="row">{children}</div>
  </Section>
)

export default SolutionsGrid
