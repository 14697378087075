import React from "react"
import { css } from "@emotion/core"

const Banner = ({ title, lead, children }) => {
  return (
    <section
      className="text-center text-light border-light"
      css={css`
        background-color: #084857;
        height: ${lead ? `50vh` : `default`};
        display: flex;
        flex-flow: column wrap;
        justify-content: space-between;
        width: 100vw;

        &:first-child {
          margin-top: 0;
        }
      `}
    >
      <hr
        css={css`
          margin: 0px !important;
          padding-top: 2px;
          border-bottom: 1px solid white;
          width: 100vw;
        `}
      />
      <div>
        <h2
          css={css`
            margin-top: 2em;
          `}
          id={lead ? "lead-title" : ""}
        >
          {title}
        </h2>
        <div
          className="lead"
          css={css`
            margin-bottom: 3em;
          `}
        >
          {children}
        </div>
      </div>
      <hr
        css={css`
          margin: 0px !important;
          padding-bottom: 2px;
          border-top: 1px solid white;
        `}
      />
    </section>
  )
}

export default Banner
