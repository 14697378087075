import React from "react"
import { css } from "@emotion/core"
import useTranslation from "../../hooks/use-translation"

const SolutionsGridItem = ({ count, title, expandText }) => {
  const t = useTranslation()

  return (
    <div
      className="col-sm-12 col-md-6 col-lg-4 item"
      css={css`
        @media (min-width: 768px) {
          :nth-of-type(2),
          :nth-of-type(4) {
            padding-right: 0;
          }
        }
        @media (min-width: 992px) {
          :nth-of-type(2),
          :nth-of-type(4) {
            padding-right: 15px;
          }
          :nth-of-type(3),
          :nth-of-type(6) {
            padding-right: 0;
          }
        }
      `}
    >
      <div
        className="card rounded-0"
        css={css`
          background-color: #fa4d60 !important;
          margin-top: 1rem;
          min-height: 130px;
        `}
      >
        <div
          className="card-body text-center"
          css={css`
            background-color: #e43f51;
          `}
        >
          <span
            className="card-text rounded-circle bg-white"
            css={css`
              color: #e43f51;
              padding: 0.5rem 0.25rem;
            `}
          >
            <img
              src="icons/icon-haken.svg"
              css={css`
                width: 1.5rem;
              `}
            />
          </span>
          <br />
          <p
            className="card-text h5"
            css={css`
              color: white;
              margin-top: 1rem;
            `}
          >
            {title}
          </p>
          <details
            css={css`
              color: white;
              margin-top: 1rem;

              summary {
                text-decoration: underline;
                outline: none;
              }
            `}
          >
            <summary className="text-xs">{t("spoiler")}</summary>
            <p>{expandText}</p>
          </details>
        </div>
        <hr
          css={css`
            margin: 0px;
            padding-bottom: 2px;
            border-top: 1px solid white;
          `}
        />
      </div>
    </div>
  )
}

export default SolutionsGridItem
