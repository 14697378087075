import React from "react"
import Image from "gatsby-image"
import Section from "./section"
import { css, Global } from "@emotion/core"

const FluidImage = ({ fluid, svg, alt }) => (
  <Section>
    <figure className="figure">
      <Global
        styles={css`
          /* Concerning Safari */
          @media (max-width: 767px) {
            .figure {
              display: block !important;
            }
          }
        `}
      />
      {fluid && (
        <Image
          alt={alt}
          fluid={fluid.childImageSharp.fluid}
          css={css`
            /* same as bootstrap container sizes */
            @media (min-width: 576px) {
              width: 540px !important;
            }
            @media (min-width: 768px) {
              width: 720px !important;
            }
            @media (min-width: 992px) {
              width: 960px !important;
            }
            @media (min-width: 1200px) {
              width: 1140px !important;
            }
          `}
          className="figure-img img-fluid container-fluid"
        />
      )}
      {svg && (
        <img
          alt={alt}
          src={svg}
          css={css`
            /* same as bootstrap container sizes */
            @media (min-width: 576px) {
              width: 540px !important;
            }
            @media (min-width: 768px) {
              width: 720px !important;
            }
            @media (min-width: 992px) {
              width: 960px !important;
            }
            @media (min-width: 1200px) {
              width: 1140px !important;
            }
          `}
          className="figure-img img-fluid container-fluid"
        />
      )}
      <figcaption
        className="figure-caption"
        dangerouslySetInnerHTML={{ __html: alt }}
      />
    </figure>
  </Section>
)

export default FluidImage
