import React, { useState } from "react"
import { css } from "@emotion/core"
import useTranslation from "../hooks/use-translation"
import { useStaticQuery, graphql } from "gatsby"
import mapStyles from "./css/map.module.css"
import FluidImage from "./image-fluid"
import Image from "gatsby-image"

const CV = () => {
  const t = useTranslation()
  let [isHoveringOverImage, setIsHoveringOverImage] = useState(false)
  const images = useStaticQuery(graphql`
    {
      map: file(relativePath: { eq: "map.svg" }) {
        publicURL
      }
      sietar: file(relativePath: { eq: "sietar.svg" }) {
        publicURL
      }
      cbs: file(relativePath: { eq: "cbs.png" }) {
        childImageSharp {
          fluid {
            ...GatsbyImageSharpFluid_withWebp
          }
        }
      }
      charta: file(relativePath: { eq: "charta.jpg" }) {
        childImageSharp {
          fluid {
            ...GatsbyImageSharpFluid_withWebp
          }
        }
      }
    }
  `)

  return (
    <section
      className="container"
      css={css`
        font-size: 1.25rem;
      `}
    >
      <h2
        css={css`
          text-align: center;
        `}
      >
        {t("cv-2-1")}
      </h2>
      <ul>
        <li>{t("cv-2-2")}</li>
        <li>{t("cv-2-3")}</li>
        <li>{t("cv-2-4")}</li>
        <li>{t("cv-2-5")}</li>
        <li>{t("cv-2-6")}</li>
        <li
          css={css`
            margin-top: 0.5rem;
          `}
        >
          {t("cv-2-7")}
        </li>
        <li
          css={css`
            list-style-type: none;
          `}
        >
          {t("cv-2-8")}
        </li>
      </ul>
      <div
        css={css`
          position: relative;
          margin-bottom: 1rem;
        `}
        onMouseOver={e => setIsHoveringOverImage(true)}
        onMouseOut={e => setIsHoveringOverImage(false)}
      >
        <FluidImage
          svg={images.map.publicURL}
          alt={`<span>
            <span class=${mapStyles.red}>${t("map-legend-red", true)}</span>
            <span class=${mapStyles.green}>${t("map-legend-green", true)}</span>
          </span>
          `}
        />
        <div
          css={css`
            display: ${isHoveringOverImage ? "flex" : "none"};
            background-color: rgb(8, 72, 87);
            opacity: 0.95;
            position: absolute;
            top: 0;
            left: 0;
            right: 0;
            bottom: 0;
            align-items: center;
            justify-content: center;

            > div > p {
              color: white;
            }

            > div {
              margin-top: 1rem;
              max-width: 80%;
            }

            overflow: scroll;
          `}
        >
          <div>
            <p>{t("quote-1")}</p>
            <p>{t("quote-2")}</p>
            <p>{t("quote-3")}</p>
            <p>{t("quote-4")}</p>
            <p>{t("quote-5")}</p>
            <p>{t("quote-6")}</p>
          </div>
        </div>
      </div>
      <h2
        css={css`
          text-align: center;
        `}
      >
        {t("cv-2-9")}
      </h2>
      <ul
        css={css`
          list-style-type: none;

          li {
            margin: 1rem 0;
          }

          /* Work around Safari: https://stackoverflow.com/a/51637750/8126260 */
          summary a * {
            pointer-events: none;
          }
        `}
      >
        <li>
          <details>
            <summary
              css={css`
                display: inline-flex;
                align-items: center;
              `}
            >
              <a
                href={"https://cbs.de"}
                css={css`
                  display: inline-block;
                  width: 10rem;
                `}
              >
                <Image alt="CBS" fluid={images.cbs.childImageSharp.fluid} />
              </a>
            </summary>
            <ul>
              <li>{t("cv-3-4-1")}</li>

              <li>{t("cv-3-2")}</li>
              <li>{t("cv-3-3")}</li>
              <li>{t("cv-3-4")}</li>
            </ul>
          </details>
        </li>

        <li>
          <details>
            <summary>{t("cv-2-10")}</summary>
            <ul>
              <li>{t("cv-2-10-1")}</li>
            </ul>
          </details>
        </li>
        <li>
          <details>
            <summary>
              <a href={"https://sietar-deutschland.de"}>
                <img
                  src={images.sietar.publicURL}
                  alt={"Sietar"}
                  css={css`
                    width: 10rem;
                  `}
                />
              </a>
            </summary>
            <ul>
              <li>
                <a
                  href={
                    "https://www.facebook.com/SIETAR-Regionalgruppe-Köln-Rhein-Ruhr-561397157727164"
                  }
                >
                  {t("cv-2-11")}
                </a>
              </li>
            </ul>
          </details>
        </li>
        <li
          css={css`
            vertical-align: middle;
            height: fit-content;
          `}
        >
          <a
            href={t("cv-3-5", true)}
            css={css`
              display: inline-block;
              width: 10rem;
              vertical-align: middle;
            `}
          >
            <Image
              alt="Charta of Diversity"
              fluid={images.charta.childImageSharp.fluid}
            />
          </a>
        </li>
        <li>
          <details>
            <summary>
              <a href={"https://Womanconf2020.com"}>{t("cv-3-6")}</a>
            </summary>
            <iframe
              css={css`
                width: 100%;
                height: 500px;
              `}
              src="https://www.youtube-nocookie.com/embed/videoseries?list=PLQ2MvmHhp5kdUocOcIQp3IP3htuAi3oZm"
              frameBorder="0"
              allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
              allowFullScreen
            />
          </details>
        </li>
      </ul>
      <h2
        css={css`
          text-align: center;
        `}
      >
        {t("cv-2-12")}
      </h2>
      <ul>
        <li>{t("cv-2-16")}</li>
        <li>
          <details>
            <summary>{t("cv-2-13")}</summary>
            <ul>
              <li>{t("cv-2-13-1")}</li>
              <li>{t("cv-2-13-2")}</li>
              <li>{t("cv-2-13-3")}</li>
              <li>{t("cv-2-13-4")}</li>
              <li>{t("cv-2-13-5")}</li>
            </ul>
          </details>
        </li>
        <li>
          <details>
            <summary>{t("cv-2-17")}</summary>
            <ul>
              <li>{t("cv-2-17-1")}</li>
              <li>{t("cv-2-17-2")}</li>
              <li>{t("cv-2-17-3")}</li>
              <li>{t("cv-2-17-4")}</li>
            </ul>
          </details>
        </li>
        <li>
          <details>
            <summary>{t("cv-2-14")}</summary>
            <ul>
              <li>{t("cv-2-14-1")}</li>
              <li>{t("cv-2-14-2")}</li>
              <li>{t("cv-2-14-3")}</li>
              <li>{t("cv-2-14-4")}</li>
              <li>{t("cv-2-14-5")}</li>
            </ul>
          </details>
        </li>
        <li>{t("cv-2-15")}</li>
        <li>{t("cv-2-18")}</li>
      </ul>
    </section>
  )
}

export default CV
