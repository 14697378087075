import { useStaticQuery, graphql } from "gatsby"
import useLanguage from "./use-language"
import React from "react"

export default function useTranslation() {
  const query = useStaticQuery(graphql`
    {
      allTranslation {
        nodes {
          key
          en
          de
        }
      }
    }
  `)
  let data = {}
  query.allTranslation.nodes.forEach(val => {
    const key = val.key
    data[key] = {
      en: val.en,
      de: val.de,
    }
  })

  const lang = useLanguage()

  return (key, plain) => {
    return !plain ? (
      <span
        dangerouslySetInnerHTML={{ __html: data[key][lang] || data[key]["en"] }}
      />
    ) : (
      data[key][lang] || data[key]["en"]
    )
  }
}
