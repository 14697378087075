import React from "react"
import Image from "gatsby-image"
import Section from "./section"
import { css } from "@emotion/core"

const RoundImage = ({ fluid, alt }) => (
  <Section>
    <figure
      className="figure mx-auto"
      css={css`
        display: block !important;
      `}
    >
      <Image
        alt={alt}
        fluid={fluid.childImageSharp.fluid}
        css={css`
          border-radius: 50%;
          width: 500px !important;
        `}
        className="figure-img img-fluid container-fluid"
      />
      <figcaption className="figure-caption">{alt}</figcaption>
    </figure>
  </Section>
)

export default RoundImage
