import React from "react"
import { css } from "@emotion/core"
import styled from "@emotion/styled"
import useTranslation from "../hooks/use-translation"

const BlueH2 = styled.h2`
  color: #084857;
  text-align: center;
  margin-top: 0.5rem;
`

const BlueP = styled.p`
  color: #084857;
  text-align: center;
  margin-top: 0.5rem;
`

const MTDiv = styled.a`
  display: block;
  margin-bottom: 4rem;

  @media (max-width: 767px) {
    margin-bottom: 2rem;
  }
`

const IconGrid = () => {
  const t = useTranslation()

  return (
    <section className="container">
      <div className="row">
        <MTDiv
          className="col-12 col-sm-12 col-md-4 col-lg-4"
          href={"/pdfs/culture-fitness-check.pdf"}
        >
          <img
            src="icons/icon-strategy.svg"
            alt="Icon Strategy"
            className="rounded-circle mx-auto"
            css={css`
              display: block;
            `}
          />
          <BlueH2>{t("icon-1")}</BlueH2>
          <BlueP>{t("icon-1-sub")}</BlueP>
        </MTDiv>
        <MTDiv
          className="col-12 col-sm-12 col-md-4 col-lg-4"
          href={"pdfs/culture-competence-workshop.pdf"}
        >
          <img
            src="icons/icon-moderation.svg"
            alt="Icon Teambuilding"
            className="rounded-circle mx-auto"
            css={css`
              display: block;
            `}
          />
          <BlueH2>{t("icon-2")}</BlueH2>
          <BlueP>{t("icon-2-sub")}</BlueP>
        </MTDiv>
        <MTDiv
          className="col-12 col-sm-12 col-md-4 col-lg-4"
          href={"/pdfs/culture-leadership-coaching.pdf"}
        >
          <img
            src="icons/icon-team.svg"
            alt="Icon Moderation"
            className="rounded-circle mx-auto"
            css={css`
              display: block;
            `}
          />
          <BlueH2>{t("icon-3")}</BlueH2>
          <BlueP>{t("icon-3-sub")}</BlueP>
        </MTDiv>
      </div>
    </section>
  )
}

export default IconGrid
