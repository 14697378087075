import React, { useState } from "react"
import Layout from "../components/layout"
import Banner from "../components/banner"
import SolutionsGrid from "../components/solutions-grid/grid"
import SolutionsGridItem from "../components/solutions-grid/item"
import { useStaticQuery, graphql } from "gatsby"
import FluidImage from "../components/image-fluid"
import IconGrid from "../components/icon-grid"
import CV from "../components/cv"
import { css } from "@emotion/core"
import RecentPosts from "../components/recent-posts"
import useInterval from "../hooks/use-interval"
import RoundImage from "../components/image-round"
import Section from "../components/section"
import useTranslation from "../hooks/use-translation"

const Index = () => {
  const t = useTranslation()

  const images = useStaticQuery(graphql`
    {
      group: file(relativePath: { eq: "group.jpeg" }) {
        childImageSharp {
          fluid {
            ...GatsbyImageSharpFluid_withWebp
          }
        }
      }
      band: file(relativePath: { eq: "band.svg" }) {
        publicURL
      }
      bandEn: file(relativePath: { eq: "band-en.svg" }) {
        publicURL
      }
      profile: file(relativePath: { eq: "profile.jpeg" }) {
        childImageSharp {
          fluid {
            ...GatsbyImageSharpFluid_withWebp
          }
        }
      }
    }
  `)

  let [count, setCount] = useState(1)
  useInterval(() => {
    let title = document.getElementById("lead-title")
    let p = document.getElementById("lead-p")

    if (count % 2 === 0) {
      title.innerText = t("slider-1-heading", true)
      p.innerText = t("slider-1-subheading", true)
    } else {
      title.innerText = t("slider-2-heading", true)
      p.innerText = t("slider-2-subheading", true)
    }

    setCount(count + 1)
  }, 5000)

  return (
    <Layout lead>
      <Banner title={t("slider-1-heading")} lead>
        <h3 className="lead" id="lead-p">
          {t("slider-1-subheading")}
        </h3>
      </Banner>
      <SolutionsGrid
        title={t("solutions-heading")}
        css={css`
          color: #d94051;
        `}
      >
        <SolutionsGridItem
          count="1"
          title={t("solutions-1")}
          expandText={t("solutions-1-expand")}
        />
        <SolutionsGridItem
          count="2"
          title={t("solutions-2")}
          expandText={t("solutions-2-expand")}
        />
        <SolutionsGridItem
          count="3"
          title={t("solutions-3")}
          expandText={t("solutions-3-expand")}
        />
        <SolutionsGridItem
          count="4"
          title={t("solutions-4")}
          expandText={t("solutions-4-expand")}
        />
        <SolutionsGridItem
          count="5"
          title={t("solutions-5")}
          expandText={t("solutions-5-expand")}
        />
        <SolutionsGridItem
          count="6"
          title={t("solutions-6")}
          expandText={t("solutions-6-expand")}
        />
      </SolutionsGrid>
      <FluidImage fluid={images.group} alt={t("group-image", true)} />

      <FluidImage
        svg={
          t("band-path", true) === "band.svg"
            ? images.band.publicURL
            : images.bandEn.publicURL
        }
      />

      <IconGrid />

      <CV />
      <RoundImage fluid={images.profile} />
      <h3
        css={css`
          text-align: center;
          margin: 5rem 0;
        `}
      >
        {t("quote")}
      </h3>
      <Banner title={t("motivation-1")}>
        <div className="container">
          <ul
            css={css`
              text-align: left;
            `}
          >
            <li>{t("motivation-2")}</li>
            <li>{t("motivation-3")}</li>
            <li>{t("motivation-4")}</li>
          </ul>
        </div>
      </Banner>

      {/* partner und engagement muss irgendwie anders, oder als Foto. ist so schwer zu machen */}
      {/*<RecentPosts />*/}
      <Banner title={t("form-title")}>
        <form
          className={"container bg-white text-dark text-left rounded-lg p-4"}
          method={"post"}
          action={
            "https://send.pageclip.co/uexZ2XImEX37OR3Xb02C5l5s5u8G18jV/crweb"
          }
        >
          <div className={"form-group"}>
            <label for={"name"}>{t("form-name")}</label>
            <input
              type={"text"}
              className={"form-control"}
              name={"name"}
              required
              aria-describedby={"name-help"}
            />
            <small className={"form-text text-muted"} id={"name-help"}>
              Required
            </small>
          </div>
          <div className={"form-group"}>
            <label htmlFor={"surname"}>{t("form-surname")}</label>
            <input
              type={"text"}
              className={"form-control"}
              name={"surname"}
              required
              aria-describedby={"surname-help"}
            />
            <small className={"form-text text-muted"} id={"surname-help"}>
              Required
            </small>
          </div>
          <div className={"form-group"}>
            <label htmlFor={"organisation"}>{t("form-organisation")}</label>
            <input
              type={"text"}
              className={"form-control"}
              name={"organisation"}
            />
          </div>
          <div className={"form-group"}>
            <label htmlFor={"position"}>{t("form-position")}</label>
            <input type={"text"} className={"form-control"} name={"position"} />
          </div>
          <div className={"form-group"}>
            <label htmlFor={"email"}>{t("form-email")}</label>
            <input
              type={"email"}
              className={"form-control"}
              name={"email"}
              required
              aria-describedby={"email-help"}
            />
            <small className={"form-text text-muted"} id={"email-help"}>
              Required
            </small>
          </div>
          <div className={"form-group"}>
            <label htmlFor={"phone"}>{t("form-phone")}</label>
            <input type={"tel"} className={"form-control"} name={"phone"} />
          </div>
          <div className={"form-group"}>
            <label htmlFor={"message"}>{t("form-message")}</label>
            <textarea
              className={"form-control"}
              name={"message"}
              rows={5}
              required
              aria-describedby={"message-help"}
            />
            <small className={"form-text text-muted"} id={"message-help"}>
              Required
            </small>
          </div>
          <button type={"submit"} className={"btn btn-primary"}>
            Submit
          </button>
        </form>
      </Banner>
    </Layout>
  )
}

export default Index
